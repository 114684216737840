import { Text } from "grommet"
import styled from "styled-components"

const AuthStyledTextLabel = styled(Text)`
    margin-left: -10px;
    font-size: 16px;
    color: #000;
    font-family: "Poppins Bold";
`

export default AuthStyledTextLabel
