import { Paragraph } from "grommet"
import styled from "styled-components"

const AuthFormSubmittedError = styled(Paragraph)`
    margin: 10px 0 0;
    font-size: 18px;
    line-height: 24px;
    color: #d85555;
    text-align: center;
    max-width: 503px;
`
export default AuthFormSubmittedError
